/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2023 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.search-bar-global {
  max-width: 14em;
  min-width: 7em;
}

.search-bar {
  margin: 0.3em 0;

  .input-group {
    border-radius: 0.4rem;
    background-color: $dusky-light-blue;
    transition: background-color ease-in-out 0.15s;
    &:hover {
      background-color: $smoky-grey;
      .search-bar-term {
        &::placeholder {
          color: $lilac-grey;
        }
      }

      .search-button {
        .search-icon svg path {
          fill: $lilac-grey;
        }
      }
    }

    .search-bar-term {
      background-color: transparent;
      font-size: 14px;
      height: 32px;
      border: none;
      max-width: 100%;
      &::placeholder {
        color: $pale-bue-grey;
        font-size: 14px;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    .search-button {
      background-color: transparent;
      font-size: 14px;
      height: 32px;

      &:focus {
        box-shadow: none;
      }

      .search-icon {
        line-height: 14px;

        svg {
          fill: $pale-bue-grey;
          vertical-align: text-top;
        }

        svg path {
          fill: $pale-bue-grey;
        }
      }
    }

    .input-group-append {
      margin-left: 0;
    }
  }

  .search-focused {
    box-shadow: 0 0 0 0 $white;
    background-color: $white;
    &:hover {
      background-color: $white;
    }

    .search-bar-term {
      background-color: transparent;
    }

    .search-button {
      background-color: transparent;
    }

    .dropdown-menu {
      display: block;
      background-color: $white;
      width: 100%;
      margin-top: 3px;

      .dropdown-active {
        background-color: $pebble-grey;
      }

      .dropdown-item-block {
        cursor: pointer;
        padding: 0;

        .dropdown-item {
          padding: 0.25rem 0.75rem;

          .search-icon {
            pointer-events: none;

            svg path {
              fill: $complementary;
            }
          }

          .dropdown-text {
            white-space: normal;
            word-break: break-word;
            width: 12em;
          }
        }

      }
    }
  }
}

