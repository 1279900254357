/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- NAVBAR SECTION ---------- */

.navbar {
  height: 3.1em;
  background-color: $midnight-blue;
  margin: 0;
  padding: 0 1em;

  .navbar-action-group {
    .action-link svg,
    .action-link svg path,
    .action-link svg polygon {
      fill: rgba(255, 255, 255, 0.8);
    }

    .global-action-icon svg,
    .global-action-icon svg path,
    .global-action-icon svg polygon {
      fill: rgba(255, 255, 255, 0.8);
    }

    .navbar-collapse {
      .navbar-nav li img {
        margin: .3em;
        padding-left: .5em;
        height: 2.4em
      }
    }

    .dropdown {
      .dropdown-menu {
        z-index: map-get($zindex, nav-max);
      }
    }

    .dropdown {
      .dropdown-menu {
        z-index: map-get($zindex, nav-max);
      }
    }
  }

  .navbar-list .dropdown.non-grouped:hover .dropdown-menu.hover-enabled,
  .navbar-nav .main-grouped:hover .menu-item-wrapper:hover .dropdown-menu.main.hover-enabled,
  .navbar-nav .non-grouped:hover .menu-item-wrapper:hover .dropdown-menu.hover-enabled {
    display: block;
    right: 0;
  }


}
.global-links-dropdown {
  z-index: map-get($zindex, nav-max);
}

.recently-viewed {
  display: block;
}

.recently-viewed-nav-header {
  overflow-x: hidden;
}

.global-links-dropdown {
  z-index: map-get($zindex, nav-max);
}

.home-nav {
  line-height: 3.1em;
}

a.home-nav-link {
  color: $white;
  line-height: 3.1em;
  padding: 0.8em 1em 1em 1em;
  padding-left: 0.5em;
}

.nav-item.active .home-nav-link svg {
  fill: $salmon-pink;
}

.recent-link scrm-svg-icon-ui > svg {
  margin: 0.3em 0.8em 0.8em 0;
}

.recent-link {
  display: inline;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: $light-grey;
}

.nav-link:hover {
  color: $off-white;
}

.nav-item.active .nav-link-nongrouped,
.nav-item.active .home-nav-link {
  color: $salmon-pink!important;
  border-top: 0.2em solid $salmon-pink;
  padding: 0.8em 1em 1.1em 1em;
  text-decoration: none;
}

.global-link-item:hover {
  color: $salmon-pink;
}

.submenu,
.globalactions {
  background-color: $dusky-blue;
  color: $light-grey;
  margin: 0;
  border: 0;
}

.submenu li {
  border: 0;
  padding: 0 0.3em;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.nav-menu-icon {
  width: 1.5em;
  height: 1.5em;
}

.action-btn-icon {
  width: 1.2em;
  height: 1.2em;
}

h4.recently-viewed-header,
h4.favorite-header {
  border-top: 0.05em solid darken($light-grey, 20%);
  color: darken($light-grey, 20%);
  font-weight: bold;
  margin: 0.8em 0 0 0;
  padding: 0.8em;
  font-size: 0.9em;
}

.navbar-1 {
  float: left;
  width: 100%;
}

.navbar-2 {
  float: right;
  padding: 0;
}

.global-action-icon {
  width: 1.5em;
  height: 1.5em;
  margin: 0;
}

.navbar-toggler:hover svg path {
  stroke: $salmon-pink;
}

.navbar {
  scrm-logo-ui img {
    margin: 0.3em;
    padding-left: 0.5em;
    height: 2.4em;
  }
  .action-group {
    display: flex;
    flex-direction: row;
    float: right;
    margin: 0.3em 0;

    .action-link {
      padding: 0 0.6rem;

      svg {
        margin: 0;
      }
    }
  }
}

a.nav-link.dropdown-toggle::after,
a.nav-link-grouped.dropdown-toggle::after,
a.nav-link-nongrouped.dropdown-toggle::after {
  vertical-align: middle;
}

.mobilenavbar {
  list-style: none;
  padding: 0;
  background-color: $midnight-blue;

  .navbar-toggler.dropdown-toggle::after {
    display: none;
  }
}

.mobile-nav-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.mobile-nav-link {
  font-size: 0.95em;
  color: $white;
  font-weight: 800;
}

.nav-collapsed-items {
  li {
    a.dropdown-toggle {
      &::after {
        margin-left: 0.5em;
      }
    }
  }
}
