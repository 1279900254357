/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- VARIABLES SECTION ---------- */

/* --------- COLOURS ---------- */

$white: #ffffff;
$off-white: #f5f5f5;
$black: black;

$light-grey: #e9e9e9;
$median-grey: #333333;
$dark-midnight-grey: #444444;
$midnight-grey: #666666;
$nepal-grey: #93a4b3;
$shell-grey: #777777;
$dusty-grey: #778591;
$smoky-grey: #9794a2;
$sky-grey: #f9f1e8;
$bright-grey: #fdedeb;
$lilac-grey: #cbc9d0;
$pale-grey: #94a6b5;
$pebble-grey: #eff2f4;
$very-light-grey: #ced4da;
$pale-bue-grey: #9e9ba8;
$dim-grey: dimgrey;
$lightgray: lightgray;
$extra-light-grey: #dee2e6;

$geyser-blue: #d7dde1;
$cool-blue: #76858f;
$dusky-blue: #666176;
$dusky-light-blue: #757083;
$midnight-blue: #534d64;
$midnight-blue-faded: #534d648f;
$midnight-blue-transparent: #534d641f;
$suite-logo-blue: #4e5063;
$powdered-blue: #c6d0d8;
$sky-blue: #a99ac9;
$astral-blue: #4b7291;
$pale-blue: #7096b5;
$bright-blue: #eeebf5;

$burnt-orange: #e65545;
$light-orange: #f05645;
$dull-orange: #ee8776;
$bright-orange: #f08377;
$vacant-orange: #a97375;
$lighter-orange: #fd7e143b;

$burnt-red: #ef8177;
$amaranth: #dc3545;

$mellow-yellow: #e2b98e;
$sandy-yellow: #e3a35f;

$lighter-salmon-pink: #faeeeb;
$salmon-pink: #f5aea6;
$coral-pink: #eb6657;
$suite-logo-pink: #e67062;

$bright-purple: #8d74cc;

$wild-willow-green: #c6ca6b;
$light-green: #93ae8a;
$dark-green: #667960;
$very-light-green: #e4f3de;

$very-light-salmon-pink: #f2d4ce;
$dark-yellow: #f59e5b;
$light-yellow: #fdf8f1;
$dark-purple: #be79be;
$light-purple: #f8f4f8;
$mild-blue: #8ab2ba;
$light-blue: #f2f6f7;
$baby-blue: #80bdff;
$border-focus-blue:$baby-blue;


$thick-green:#83bb73;
$lighter-green:#f1ffed;
$thick-orange: #d1c369;
$very-lighter-orange: #fffbe0;

/* ----Base colors -- */

$danger: #ee8776;

/* ----Buttons -- */

$btn-danger: $danger;


/* --------- PREDEFINED STYLES ---------- */

$main: $midnight-blue;
$main-light: $dusky-light-blue;
$main-light-hover: $smoky-grey;
$primary: $cool-blue;
$secondary: $nepal-grey;
$complementary: $bright-purple;
$complementary-light: $sky-blue;


/* --------- BREAKPOINTS ---------- */

$breakpoint-xxsmall: 12em;
$breakpoint-xsmall: 24em;
$breakpoint-small: 30em;
$breakpoint-medium: 36em;
$breakpoint-large: 48em;
$breakpoint-xlarge: 62em;
$breakpoint-xxlarge: 75em;
$breakpoint-xxxlarge: 105em;

/* --------- Z-INDEXES ---------- */

$zindex: (
  app-high: 2000,
  app-mid: 1750,
  app-low: 1510,
  modal-high: 1500,
  modal-mid: 1250,
  modal-low: 1040,
  nav-max: 1030,
  nav-high: 1019,
  nav-mid: 750,
  nav-low: 500,
  page-high: 499,
  page-mid: 50,
  page-low: 10,
);

$cross-background-url: "../../images/checkbox_cross.svg"
