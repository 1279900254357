/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */
@media all and (max-width: $breakpoint-xlarge) {
    .column-chooser-modal {
      .modal-dialog {
        max-width: 35em;
      }
      .modal-body {
          overflow: auto;
      }
      .close-button {
        color: $shell-grey;
        font-size: 1.5em;
        border: none;
      }
      .column-chooser-container {
        margin: 1em 0.75em 1.5em 0.75em;
        width: 45%;
      }
      .modal-header, .modal-footer {
        background: $white;
        padding: 0;
      }
    }
  }

  @media all and (max-width: $breakpoint-small) {
    .column-chooser-modal {
      .modal-dialog {
        width: auto;
      }
      .column-chooser-container {
        width: 93%;
      }
    }
  }
