.record-thread-sidebar-widget {
  min-height: 6rem;
}

.record-thread-create-container {
  padding: .5rem .75rem;
}

.record-thread-load-more {
  .load-more-button {
    font-size: .775rem;
    color: $midnight-blue;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
}


.record-thread-item.record-thread-list-item {
  padding: .5rem .75rem;

  .record-thread-item-header {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .record-thread-item-body {
    color: $dim-grey;
    background-color: $very-light-green;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.1rem;
    box-shadow: 0 0.175rem 0.3rem rgb(0 0 0 / 15%);

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    word-break: break-all;
    background-clip: border-box;
    border-radius: .25rem;

    img {
      max-width: 100%;
      height: auto;
    }

  }

  .item-title {
    color: $dark-green;
  }

  .record-thread-item-body.collapsed .item-content {
    max-height: 50px;
    overflow: hidden;
  }

  .record-thread-item-body.collapsed .item-content-extra {
    display: none !important;
  }

  .record-thread-item-body.expanded .item-content {
    height: auto;
  }

  .record-thread-item-collapse {

    .collapse-button {
      margin: 0;
      padding: 0;
      font-size: .775rem;
      color: $light-green;
      font-weight: 700;
    }
  }
}

.fadeout {
  bottom: 1.3em;
  height: 2.6em;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, $very-light-green 100%);
  position: absolute;
  width: calc(100% - 1rem);
}
