/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */
.composite.email-fields {
  .field-group {
    .field-group-item:first-child {
      width: 100% !important;
    }

    .field-group-item {
      width: 25% !important;
      align-self: flex-start !important;
      @media all and (max-width: $breakpoint-xxlarge) {
        line-height: 1;
      }
    }
  }
}

.composite-field {
  .checkmark {
    margin-left: 0.68em;
    @media all and (max-width: $breakpoint-xxlarge) {
      margin-top: 0.3em;
    }
  }
  .checkbox-field {
    .checkmark {
      margin-left: 0.68em;
      @media all and (max-width: $breakpoint-xxlarge) {
        margin-top: 0.3em;
      }
    }
    position: relative;
    top: 0.3em;
    @media all and (max-width: $breakpoint-xxlarge) {
      padding-bottom: 0!important;
    }
  }
}

.line-item-entry-buttons-label-padding {
  padding-top: 3.5em;
  @media all and (max-width: $breakpoint-xxlarge) {
    padding-top: 2.2em;
  }
}

.line-item-entry-buttons-no-label-padding {
  padding-top: 0.7em;
}
